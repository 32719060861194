// src/components/OrderDetails.js
import React from 'react';
import {
    Card,
    CardContent,
    Typography,
    Chip,
    Grid,
    Button,
    Autocomplete,
    TextField,
    CircularProgress,
    Box,
} from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';
import he from 'he';

// Mintsoft mappings
const mintsoftStatusMapping = {
    1: 'NEW',
    2: 'PRINTED',
    3: 'CANCELLED',
    4: 'DESPATCHED',
    7: 'HOLDING',
    9: 'ONBACKORDER',
    15: 'AWAITING PICKING',
    17: 'PICKED',
};

const mintsoftStatusColorMapping = {
    1: 'primary',
    2: 'primary',
    3: 'error',
    4: 'success',
    7: 'secondary',
    9: 'secondary',
    15: 'info',
    17: 'primary',
};

// KornitX mappings
const kornitxStatusColorMapping = {
    'Received': 'primary',
    'In Production': 'primary',
    'Dispachted': 'success',
    'Cancelled': 'error',
    'On Hold': 'secondary',
    'QC Qurey': 'error',
    'Consolidating': 'secondary',
};

const OrderDetails = ({
    order,
    courierServices,
    selectedCourierService,
    setSelectedCourierService,
    numberOfParcels,
    setNumberOfParcels,
    isEditingShipment,
    onToggleEdit,
    checkingStatus,
    kornitxOrderStatus,
    fetchKornitxStatus,
    orderSource, // "mintsoft" or "kornitx"
}) => {
    if (!order) return null;

    if (orderSource === "kornitx") {
        // For KornitX orders, calculate total items from order.items.items
        const totalQtyKX =
            order.items?.items?.reduce((sum, item) => sum + (item.quantity || 0), 0) || 0;

        return (
            <Card variant="outlined" sx={{ mb: 2, boxShadow: 1, borderRadius: 2 }}>
                <CardContent sx={{ p: 2 }}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                        <Box display="flex" alignItems="center" gap={2}>
                            <Typography variant="subtitle1" fontWeight={600}>
                                KornitX Order ID: {order.id}
                            </Typography>
                            <Chip
                                label={order.status_name}
                                color={kornitxStatusColorMapping[order.status_name] || 'default'}
                                size="small"
                            />
                        </Box>
                        {/* KornitX orders are read-only; no edit button */}
                    </Box>

                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="caption" fontWeight="bold">
                                External Ref
                            </Typography>
                            <Typography variant="body2">{order.external_ref}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="caption" fontWeight="bold">
                                Shipping Carrier
                            </Typography>
                            <Typography variant="body2">{order.shipping_carrier}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="caption" fontWeight="bold">
                                Shipping Method
                            </Typography>
                            <Typography variant="body2">{order.shipping_method}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="caption" fontWeight="bold">
                                Total Items
                            </Typography>
                            <Typography variant="body2">{totalQtyKX}</Typography>
                        </Grid>
                        {order.GiftMessage && (
                            <Grid item xs={12}>
                                <Typography variant="caption" fontWeight="bold">
                                    Gift Message
                                </Typography>
                                <Typography variant="body2">
                                    {he.decode(order.GiftMessage)}
                                </Typography>
                            </Grid>
                        )}
                        {/* Add more KornitX-specific fields as needed */}
                    </Grid>
                </CardContent>
            </Card>
        );
    }

    // Default: Mintsoft order details
    const {
        ID,
        OrderNumber,
        ExternalOrderReference,
        OrderStatusId,
        CourierServiceId,
        CourierServiceName,
        GiftMessages,
        OrderItems,
        NumberOfParcels,
    } = order;

    const totalQty =
        OrderItems?.reduce((sum, item) => sum + (item.Quantity || 0), 0) || 0;
    const totalWeight =
        OrderItems?.reduce((sum, item) => {
            const weight = item.product?.Weight || 0;
            return sum + weight * item.Quantity;
        }, 0) || 0;

    const currentCourierService =
        courierServices.find((s) => s.ID === CourierServiceId);

    return (
        <Card variant="outlined" sx={{ mb: 2, boxShadow: 1, borderRadius: 2 }}>
            <CardContent sx={{ p: 2 }}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                    <Box display="flex" alignItems="center" gap={2}>
                        <Typography variant="subtitle1" fontWeight={600}>
                            Order ID: {ID}
                        </Typography>
                        <Chip
                            label={mintsoftStatusMapping[OrderStatusId]}
                            color={mintsoftStatusColorMapping[OrderStatusId]}
                            size="small"
                        />
                    </Box>
                    <Button variant="outlined" size="small" onClick={onToggleEdit}>
                        {isEditingShipment ? 'Cancel' : 'Edit Shipment'}
                    </Button>
                </Box>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="caption" fontWeight="bold">
                            Order Number
                        </Typography>
                        <Typography variant="body2">{OrderNumber}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="caption" fontWeight="bold">
                            External Ref
                        </Typography>
                        <Typography variant="body2">{ExternalOrderReference}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="caption" fontWeight="bold">
                            Total Items
                        </Typography>
                        <Typography variant="body2">{totalQty}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="caption" fontWeight="bold">
                            Total Weight
                        </Typography>
                        <Typography variant="body2">
                            {totalWeight ? `${totalWeight.toFixed(2)} kg` : 'N/A'}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Typography variant="caption" fontWeight="bold">
                            Courier Service
                        </Typography>
                        {isEditingShipment ? (
                            <Autocomplete
                                options={courierServices}
                                getOptionLabel={(option) => option.Name}
                                value={
                                    courierServices.find((s) => s.ID === selectedCourierService) ||
                                    null
                                }
                                onChange={(e, newVal) =>
                                    setSelectedCourierService(newVal ? newVal.ID : '')
                                }
                                renderInput={(params) => (
                                    <TextField {...params} size="small" variant="outlined" />
                                )}
                            />
                        ) : (
                            <Typography variant="body2">
                                {currentCourierService?.Name || CourierServiceName}
                            </Typography>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="caption" fontWeight="bold">
                            No. of Parcels
                        </Typography>
                        {isEditingShipment ? (
                            <TextField
                                type="number"
                                size="small"
                                fullWidth
                                value={numberOfParcels}
                                onChange={(e) => setNumberOfParcels(e.target.value)}
                                inputProps={{ min: 1, max: totalQty }}
                            />
                        ) : (
                            <Typography variant="body2">
                                {NumberOfParcels || numberOfParcels}
                            </Typography>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="caption" fontWeight="bold">
                            KornitX Status (Personalised Items Only)
                        </Typography>
                        <Box display="flex" alignItems="center" gap={1}>
                            {checkingStatus ? (
                                <CircularProgress size={16} />
                            ) : (
                                <Chip
                                    label={kornitxOrderStatus}
                                    color={kornitxStatusColorMapping[kornitxOrderStatus] || 'default'}
                                    size="small"
                                />
                            )}
                            <Button size="small" variant="outlined" onClick={fetchKornitxStatus}>
                                <SyncIcon fontSize="small" />
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="caption" fontWeight="bold">
                            Gift Message
                        </Typography>
                        <Typography
                            variant="body2"
                            sx={{ whiteSpace: 'pre-line', wordBreak: 'break-word' }}
                        >
                            {GiftMessages || 'None'}
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default OrderDetails;
