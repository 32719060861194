// frontend\src\components\ManageBatches.js
import React, { useState } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  Select,
  MenuItem,
  TextField,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Snackbar,
  Alert
} from '@mui/material';
import Layout from './Layout';

const clients = [
    { id: 34, name: 'Au Vodka',        shortName: 'AU' },
    { id: 33, name: 'Branston',        shortName: 'BS' },
    { id: 20, name: 'Chapel Down',     shortName: 'CD' },
    { id: 13, name: 'Domaine Watson',  shortName: 'DW' },
    { id: 11, name: 'Echo Three',      shortName: 'E3' },
    { id: 30, name: 'Enshrouded Store',shortName: 'ENS' },
    { id: 17, name: 'Famous Grouse',   shortName: 'FG' },
    { id: 8,  name: 'Glenmorangie',    shortName: 'GL' },
    { id: 22, name: 'Glenfiddich',     shortName: 'GF' },
    { id: 29, name: 'Food Hugggers UK',shortName: 'FH' },
    { id: 27, name: 'Lady A',          shortName: 'LA' },
    { id: 19, name: 'Moonpig UAT',     shortName: 'MP' },
    { id: 28, name: 'NOOH by La Coste',shortName: 'NOOH' },
    { id: 14, name: 'Renais',          shortName: 'RS' },
    { id: 10, name: 'Schwartz',        shortName: 'SWZ' },
    { id: 21, name: 'Who Gives a Crap',shortName: 'WGAC' },
  ].sort((a, b) => a.name.localeCompare(b.name));

const ITEMS_PER_PAGE = 35;

const ManageBatches = () => {
  const [clientId, setClientId] = useState('');
  const [includeTags, setIncludeTags] = useState('');
  const [excludeTags, setExcludeTags] = useState('CardOnly');
  const [orders, setOrders] = useState([]);
  const [totalOrders, setTotalOrders] = useState(0);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const handleSnackbarClose = () =>
    setSnackbar((prev) => ({ ...prev, open: false }));

  // Converts Base64 to Blob for printing
  function b64toBlob(b64Data, contentType) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    const sliceSize = 512;

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      byteArrays.push(new Uint8Array(byteNumbers));
    }
    return new Blob(byteArrays, { type: contentType });
  }

  /**
   * Fetch the latest orders from the server. 
   * 
   * @param {boolean} silent - If true, skip showing spinner to avoid flickering on quick fetch before create batch.
   * @returns {object} The response data containing { success, orders, totalOrders } 
   */
  const fetchOrders = async (silent = false) => {
    if (!clientId) {
      setSnackbar({
        open: true,
        message: 'Please select a client.',
        severity: 'warning',
      });
      return { success: false, orders: [], totalOrders: 0 };
    }
    if (!silent) setLoading(true);
    setOrders([]);
    try {
      const params = { clientId };
      if (includeTags.trim()) params.includeTags = includeTags.trim();
      if (excludeTags.trim()) params.excludeTags = excludeTags.trim();

      const res = await axios.get('/api/manage-batches/orders', { params });

      if (res.data.success) {
        setOrders(res.data.orders);
        setTotalOrders(res.data.totalOrders);
        if (res.data.orders.length === 0) {
          setSnackbar({
            open: true,
            message: 'No orders matched your criteria.',
            severity: 'info',
          });
        }
      } else {
        setSnackbar({
          open: true,
          message: res.data.message || 'No orders returned.',
          severity: 'info',
        });
      }
      return res.data;
    } catch (err) {
      console.error(err);
      setSnackbar({
        open: true,
        message: `Fetch failed: ${err.message}`,
        severity: 'error',
      });
      return { success: false, orders: [], totalOrders: 0 };
    } finally {
      if (!silent) setLoading(false);
    }
  };

  /**
   * Re-fetch orders right before creating a batch to ensure the list is fresh
   * and no one else has taken those orders in the meantime.
   */
  const createBatch = async () => {
    // Always re-fetch the latest orders to prevent concurrency issues
    setLoading(true);
    try {
      // 1) Re-fetch orders (in "silent" mode, so we only show spinner once)
      const freshData = await fetchOrders(true);
      const freshOrders = freshData.orders || [];

      // 2) If still no orders, we cannot create a batch
      if (freshOrders.length === 0) {
        setSnackbar({
          open: true,
          message: 'No orders to create a batch from after refresh.',
          severity: 'warning',
        });
        setLoading(false);
        return;
      }

      // 3) Create the batch using the new fresh orders
      const firstPageOrders = freshOrders.slice(0, ITEMS_PER_PAGE);
      const orderIds = firstPageOrders.map((order) => order.ID);
      const selectedClientObj = clients.find((c) => c.id === clientId);
      const shortName = selectedClientObj?.shortName || 'XX';  // fallback if no shortName
      const res = await axios.post('/api/manage-batches/create-batch', {
        orderIds,
        clientId,
        includeTags,
        shortName, // <-- we add this so the server can use it
      });

      if (res.data.success && res.data.batch.Success) {
        setSnackbar({
          open: true,
          message: `Batch ${res.data.batch.ID} created: ${res.data.batch.Message}`,
          severity: 'success',
        });

        // If merged PDF is present, open in new window for printing
        if (res.data.mergedDespatchNote) {
          const pdfBlob = b64toBlob(res.data.mergedDespatchNote, 'application/pdf');
          const pdfUrl = URL.createObjectURL(pdfBlob);
          const printWindow = window.open(pdfUrl);
          if (printWindow) {
            printWindow.onload = () => {
              printWindow.print();
            };
          }
        }

        // Clear order list
        setOrders([]);
        setTotalOrders(0);
      } else {
        setSnackbar({
          open: true,
          message: `Batch failed: ${
            res.data.batch?.Message || 'Unknown error'
          }`,
          severity: 'error',
        });
      }
    } catch (err) {
      console.error(err);
      setSnackbar({
        open: true,
        message: `Batch creation failed: ${err.message}`,
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  // Manage table pagination
  const displayedOrders = orders.slice(0, ITEMS_PER_PAGE);

  return (
    <Layout>
      <Paper
        sx={{
          p: 3,
          m: 2,
          display: 'flex',
          flexDirection: 'column',
          height: '85vh',
          boxShadow: 4,
          borderRadius: 2,
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 1 }}>
          📦 Manage Batches
        </Typography>

        <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
          Create and manage picking batches from new Mintsoft orders.
        </Typography>

        {/* Controls for picking orders */}
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            alignItems: 'center',
            mb: 3,
          }}
        >
          <Box sx={{ flex: 1 }}>
            <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
              Select Client
            </Typography>
            <Select
              value={clientId}
              onChange={(e) => setClientId(e.target.value)}
              displayEmpty
              fullWidth
              size="small"
            >
              <MenuItem value="" disabled>
                Choose a client
              </MenuItem>
              {clients.map((client) => (
                <MenuItem key={client.id} value={client.id}>
                  {client.name}
                </MenuItem>
              ))}
            </Select>
          </Box>

          <Box sx={{ flex: 1 }}>
            <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
              Include Tags (Optional)
            </Typography>
            <TextField
              label="Include Tags"
              variant="outlined"
              size="small"
              value={includeTags}
              onChange={(e) => setIncludeTags(e.target.value)}
              fullWidth
            />
          </Box>

          <Box sx={{ flex: 1 }}>
            <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
              Exclude Tags (Optional)
            </Typography>
            <TextField
              label="Exclude Tags"
              variant="outlined"
              size="small"
              value={excludeTags}
              onChange={(e) => setExcludeTags(e.target.value)}
              fullWidth
            />
          </Box>

          <Button
            variant="contained"
            onClick={() => fetchOrders(false)}
            disabled={loading}
            sx={{ height: 40, mt: 2.4 }}
          >
            {loading ? <CircularProgress size={24} /> : 'Load Orders'}
          </Button>
        </Box>

        {/* Summary */}
        <Box
          sx={{
            mb: 2,
            p: 2,
            bgcolor: 'background.default',
            borderRadius: 2,
            boxShadow: (theme) => `0 0 3px ${theme.palette.divider}`,
          }}
        >
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
            Orders To Be Processed:{' '}
            <Typography variant="inherit" component="span" color="primary">
              {totalOrders}
            </Typography>
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Only the first {ITEMS_PER_PAGE} orders will be used to create a
            single batch.
          </Typography>
        </Box>

        {/* Orders Table */}
        <TableContainer sx={{ flexGrow: 1, overflowY: 'auto', mb: 2 }}>
          <Table
            size="small"
            stickyHeader
            sx={{
              borderRadius: 2,
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>Order ID</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Order Number</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>
                  External Ref
                </TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Tags</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Total Items</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>
                  Courier Service
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {displayedOrders.map((order) => (
                <TableRow key={order.ID} hover sx={{ cursor: 'default' }}>
                  <TableCell>{order.ID}</TableCell>
                  <TableCell>{order.OrderNumber}</TableCell>
                  <TableCell>{order.ExternalOrderReference}</TableCell>
                  <TableCell>{order.Tags || '-'}</TableCell>
                  <TableCell>{order.TotalItems}</TableCell>
                  <TableCell>{order.CourierServiceName}</TableCell>
                </TableRow>
              ))}

              {displayedOrders.length === 0 && (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No orders to display.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Batch Creation Button */}
        <Box sx={{ mt: 'auto', display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            color="success"
            onClick={createBatch}
            disabled={loading || orders.length === 0}
            sx={{ textTransform: 'none', fontWeight: 600 }}
          >
            {loading ? <CircularProgress size={24} /> : 'Create Batch'}
          </Button>
        </Box>

        {/* Snackbar for Notifications */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }} 
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={snackbar.severity}
            sx={{ width: '100%' }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Paper>
    </Layout>
  );
};

export default ManageBatches;
