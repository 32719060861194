// src/components/SearchForm.js
import React, { useEffect } from 'react';
import {
  Paper, Typography, Grid, FormControl, FormLabel,
  RadioGroup, FormControlLabel, Radio, InputLabel,
  Select, MenuItem, TextField, Button, CircularProgress
} from '@mui/material';

// Sample client list (only used for Mintsoft orders)
const clients = [
  { id: 34, name: 'Au Vodka' },
  { id: 33, name: 'Branston' },
  { id: 20, name: 'Chapel Down' },
  { id: 13, name: 'Domaine Watson' },
  { id: 11, name: 'Echo Three' },
  { id: 30, name: 'Enshrouded Store' },
  { id: 17, name: 'Famous Grouse' },
  { id: 8, name: 'Glenmorangie' },
  { id: 22, name: 'Glenfiddich' },
  { id: 29, name: 'Food Hugggers UK' },
  { id: 27, name: 'Lady A' },
  { id: 19, name: 'Moonpig UAT' },
  { id: 28, name: 'NOOH by La Coste' },
  { id: 14, name: 'Renais' },
  { id: 10, name: 'Schwartz' },
  { id: 21, name: 'Who Gives a Crap' },
].sort((a, b) => a.name.localeCompare(b.name));

const SearchForm = ({
  searchParams, setSearchParams, printers, selectedPrinter,
  setSelectedPrinter, onSearch, loading, inputRef, isDispatch, orderSource
}) => {

  useEffect(() => {
    if (orderSource === 'kornitx' && inputRef.current) {
      inputRef.current.focus();
    }
  }, [orderSource, inputRef]);

  return (
    <Paper
      sx={{
        p: 3,
        boxShadow: 4,
        borderRadius: 2,
        mb: 3,
        backgroundColor: 'background.paper',
      }}
    >
      <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
        {isDispatch
          ? orderSource === "kornitx" 
              ? 'KornitX Dispatch Station'
              : 'Mintsoft Dispatch Station'
          : 'Reprint Shipping Label & Additional Documents'}
      </Typography>

      <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
        {isDispatch
          ? orderSource === "kornitx"
              ? 'Search for a KornitX order by Order Number.'
              : 'Dispatch new orders with correct label printing and optional editing of Courier/Parcels.'
          : 'Search for an order to reprint shipping label and any additional documents.'}
      </Typography>

      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12}>
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend" sx={{ fontWeight: 'bold', mb: 1 }}>
              Search By
            </FormLabel>
            <RadioGroup
              row
              name="searchType"
              value={searchParams.searchType}
              onChange={(e) =>
                setSearchParams((prev) => ({ ...prev, searchType: e.target.value }))
              }
            >
              <FormControlLabel
                value="orderNumber"
                control={<Radio />}
                label="Order Number"
              />
              {orderSource !== "kornitx" &&(
              <FormControlLabel
                value="orderId"
                control={<Radio />}
                label="Order Id"
              />)}
            </RadioGroup>
          </FormControl>
        </Grid>

        {/* For Mintsoft orders only, show Client select */}
        {orderSource !== "kornitx" && searchParams.searchType === 'orderNumber' && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="client-select-label">Select Client</InputLabel>
              <Select
                labelId="client-select-label"
                value={searchParams.selectedClientId}
                label="Select Client"
                onChange={(e) =>
                  setSearchParams((prev) => ({ ...prev, selectedClientId: e.target.value }))
                }
              >
                {clients.map((client) => (
                  <MenuItem key={client.id} value={client.id}>
                    {client.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}

        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel id="printer-select-label">Select Printer (Shipping Label)</InputLabel>
            <Select
              labelId="printer-select-label"
              value={selectedPrinter}
              label="Select Printer (Shipping Label)"
              onChange={(e) => setSelectedPrinter(e.target.value)}
            >
              {printers.map((printer, index) => (
                <MenuItem key={index} value={printer}>
                  {printer}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <TextField
            label={
              searchParams.searchType === 'orderNumber'
                ? 'Order Number (External Ref)'
                : 'Order Id (Scan the Barcode on Packing Slip)'
            }
            fullWidth
            value={searchParams.orderNumber}
            onChange={(e) =>
              setSearchParams((prev) => ({ ...prev, orderNumber: e.target.value }))
            }
            onKeyPress={(e) => {
              if (e.key === 'Enter') onSearch();
            }}
            inputRef={inputRef}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Button
            variant="outlined"
            fullWidth
            onClick={() =>
              setSearchParams((prev) => ({ ...prev, orderNumber: '' }))
            }
            disabled={loading}
          >
            Clear
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={onSearch}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} />
            ) : isDispatch ? (
              'Search'
            ) : (
              'Search & Reprint'
            )}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SearchForm;
