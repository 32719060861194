// src/components/OrderItems.js
import React, { useState } from 'react';
import {
    Box,
    Card,
    CardContent,
    Typography,
    Dialog,
    DialogContent,
    DialogActions,
    Button,
} from '@mui/material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';

const OrderItems = ({
    order,
    orderProducts,
    lineImagesMap,
    confirmedLineIds,
    setConfirmedLineIds,
    isPersonalised,
    orderSource, // "mintsoft" or "kornitx"
}) => {
    const [zoomImage, setZoomImage] = useState(null);

    // For Mintsoft orders, allow toggling confirmation.
    // For KornitX orders, individual toggling is disabled.
    const handleToggleConfirm = (itemId) => {
        if (orderSource === 'mintsoft') {
            setConfirmedLineIds((prev) =>
                prev.includes(itemId)
                    ? prev.filter((id) => id !== itemId)
                    : [...prev, itemId]
            );
        }
    };

    // Determine which list of items to use:
    const itemsList =
        orderSource === 'kornitx' ? (order.items?.items || []) : order.OrderItems;

    return (
        <>
            <Box sx={{ mt: 2, display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                {itemsList.map((item) => {
                    // For Mintsoft orders, use product details and personalised images.
                    // For KornitX orders, use item fields directly.
                    let prod = {};
                    let finalImage = '';
                    let quantity = 0;
                    let sku = '';
                    let description = '';
                    let itemId = '';
                    let artwork_barcode = '';

                    if (orderSource === 'mintsoft') {
                        const productId = item.ProductId;
                        prod = orderProducts[productId] || {};
                        const imageData = lineImagesMap[item.ID];
                        finalImage = imageData?.thumbnailUrl || prod.ImageURL;
                        quantity = item.Quantity;
                        sku = prod.SKU || item.SKU;
                        description = prod.Name || prod.Description || 'N/A';
                        itemId = imageData?.kxItemId || '';
                        artwork_barcode = imageData?.artwork_barcode || '';
                    } else {
                        // KornitX order item structure:
                        // item contains: id, description, mapped_sku, quantity, artwork (array)
                        finalImage = item.thumbnail?.url || '';
                        quantity = item.quantity;
                        sku = item.mapped_sku || item.sku || '';
                        description = item.description;
                        itemId = item.id;
                        artwork_barcode = item.artwork_barcode;
                    }

                    // Use item.ID for Mintsoft; for KornitX, use item.id
                    const isConfirmed =
                        orderSource === 'mintsoft'
                            ? confirmedLineIds.includes(item.ID)
                            : confirmedLineIds.includes(item.id);

                    return (
                        <Card
                            key={orderSource === 'mintsoft' ? item.ID : item.id}
                            sx={{
                                width: 200,
                                position: 'relative',
                                boxShadow: 2,
                                borderRadius: 2,
                                cursor: orderSource === 'mintsoft' ? 'pointer' : 'default',
                                border: isConfirmed ? '2px solid green' : 'none',
                            }}
                            onClick={() =>
                                handleToggleConfirm(orderSource === 'mintsoft' ? item.ID : item.id)
                            }
                        >
                            <Box sx={{ position: 'relative', height: 200 }}>
                                {finalImage ? (
                                    <Box
                                        component="img"
                                        src={finalImage}
                                        alt={description}
                                        sx={{
                                            height: '100%',
                                            width: '100%',
                                            objectFit: 'cover',
                                            display: 'block',
                                        }}
                                        onClick={(e) => e.stopPropagation()} // still allow zoom functionality
                                    />
                                ) : (
                                    <Box
                                        sx={{
                                            height: '100%',
                                            width: '100%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            bgcolor: 'grey.200',
                                        }}
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        <Typography variant="caption">No Image</Typography>
                                    </Box>
                                )}

                                {/* Top-left quantity tag */}
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        bgcolor: 'primary.main',
                                        color: 'white',
                                        px: 1.5,
                                        py: 0.5,
                                        fontWeight: 'bold',
                                        borderBottomRightRadius: '8px',
                                    }}
                                >
                                    Qty: {quantity}
                                </Box>

                                {/* Zoom icon */}
                                {finalImage && (
                                    <Box
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setZoomImage(finalImage);
                                        }}
                                        sx={{
                                            position: 'absolute',
                                            bottom: 8,
                                            right: 8,
                                            backgroundColor: 'rgba(0,0,0,0.5)',
                                            borderRadius: '50%',
                                            padding: 0.5,
                                            cursor: 'pointer',
                                            zIndex: 2,
                                        }}
                                    >
                                        <ZoomInIcon sx={{ color: 'white' }} />
                                    </Box>
                                )}

                                {/* Confirm overlay: show for both Mintsoft and KornitX orders */}
                                {isConfirmed && (
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            height: '100%',
                                            width: '100%',
                                            bgcolor: 'rgba(0,200,0,0.4)',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            pointerEvents: 'none',
                                            zIndex: 1,
                                        }}
                                    >
                                        <Typography variant="h1" sx={{ color: 'white' }}>
                                            ✔
                                        </Typography>
                                    </Box>
                                )}
                            </Box>

                            <CardContent sx={{ p: 1 }}>
                                <Typography variant="caption" display="block" sx={{ fontWeight: 'bold' }}>
                                    SKU: {sku}
                                </Typography>
                                <Typography variant="caption" display="block">
                                    {description}
                                </Typography>

                                {/* 🆕 Add this below description */}
                                {!finalImage && isPersonalised?.(item.ID) && (
                                    <Typography variant="caption" color="warning.main">
                                        Awaiting Artwork
                                    </Typography>
                                )}

                                {orderSource === 'mintsoft' && isPersonalised?.(item.ID) && (
                                    <>
                                        <Typography variant="caption" display="block">
                                            Item Id: {itemId}
                                        </Typography>
                                        <Typography variant="caption" display="block">
                                            Artwork Barcode: {artwork_barcode}
                                        </Typography>
                                    </>
                                )}

                                {orderSource === 'kornitx' && (
                                    <>
                                        <Typography variant="caption" display="block">
                                            Item Id: {itemId}
                                        </Typography>
                                        <Typography variant="caption" display="block">
                                            Artwork Barcode: {artwork_barcode}
                                        </Typography>
                                    </>
                                )}
                            </CardContent>
                        </Card>
                    );
                })}
            </Box>

            {/* Zoom Dialog */}
            <Dialog open={Boolean(zoomImage)} onClose={() => setZoomImage(null)} maxWidth="lg">
                <DialogContent>
                    {zoomImage && (
                        <Box
                            component="img"
                            src={zoomImage}
                            alt="Zoomed Image"
                            sx={{ width: '100%', height: 'auto' }}
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setZoomImage(null)}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default OrderItems;
