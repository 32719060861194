// frontend/src/components/Sidebar.js

import React, { useContext, useState, useEffect } from 'react';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import {
    Drawer,
    Button,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Divider,
    Collapse,
    Box
} from '@mui/material';
import {
    ExpandLess,
    ExpandMore,
    Dashboard,
    CardGiftcard,
    History,
    BarChart,
    People,
    ShoppingBag,
    Diversity3,
    Assessment,
    Description,
    Build,
    Book,
    LocalShipping,
} from '@mui/icons-material';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import { AuthContext } from '../contexts/AuthContext';
import { useTheme } from '@mui/material/styles';

const drawerWidth = 250;

const Sidebar = ({ mobileOpen, handleDrawerToggle }) => {
    const { permissions } = useContext(AuthContext); // Get permissions from AuthContext
    const location = useLocation(); // Get current path
    const [tharsternOpen, setTharsternOpen] = useState(false);
    const theme = useTheme(); // Access the theme

    const hasPermission = (permissionName) => {
        return permissions && permissions.includes(permissionName); // Ensure permissions is an array
    };

    // List of menu items
    const menuItems = [
        {
            label: 'Apps - IV Creative',
            isTitle: true,
        },
        {
            label: 'Dashboard',
            icon: <Dashboard />,
            link: '/dashboard',
        },
        {
            label: 'Power BI Reports',
            icon: <Dashboard />,
            link: '/reports-dashboard',
        },
        {
            label: 'Client\'s Orders',
            icon: <ShoppingBag />,
            link: '/orders',
        },
        {
            label: 'Shipping Station',
            icon: <LocalShipping />,
            link: '/linx-dispatch',
        },
        {
            label: 'Manage Batches',
            icon: <FactCheckIcon />,
            link: '/manage-batches',
        },

        hasPermission('ManageClients') && {
            label: 'Clients Management',
            icon: <Diversity3 />,
            link: '/clients',
        },
        hasPermission('ViewReports') && {
            label: 'Reports',
            icon: <Assessment />,
            link: '/reports',
        },

        // Tharstern Menu
        /*hasPermission('ViewTharsternJobs') && hasPermission('ViewTharsternEstimates') && hasPermission('ViewTharsternCustomers') && {
            label: 'Tharstern',
            icon: <Build />, // Choose an appropriate icon
            submenu: [
                {
                    label: 'Jobs',
                    icon: <Description />, // Choose an appropriate icon
                    link: '/tharstern/jobs',
                    permission: 'ViewTharsternJobs',
                },
                {
                    label: 'Estimates',
                    icon: <Description />,
                    link: '/tharstern/estimates',
                    permission: 'ViewTharsternEstimates',
                },
                {
                    label: 'Customers',
                    icon: <Description />,
                    link: '/tharstern/customers',
                    permission: 'ViewTharsternCustomers',
                },
            ],
        },*/

        {
            label: 'Apps - Moonpig',
            isTitle: true,
        },
        {
            label: 'Buy A Gift Report',
            icon: <CardGiftcard />,
            link: '/bagorders',
        },
        {
            label: 'View Orders History',
            icon: <History />,
            link: '/history',
        },
        {
            label: 'Grafana KPI Dashboard',
            icon: <BarChart />,
            link: '/grafanakpi',
        },
        {
            label: 'System Setting',
            isTitle: true,
        },
        hasPermission('ManageUsers') && {
            label: 'Users Management',
            icon: <People />,
            link: '/user-management',
        },
        hasPermission('ViewLogs') && {
            label: 'Logs',
            icon: <Description />, 
            link: '/logs',
        },

    ].filter(Boolean); // Filter out `null` values when permissions don't match

    // Function to render menu items recursively
    const renderMenuItems = (items) => {
        return items.map((item, index) => {
            if (item.isTitle) {
                return (
                    <ListItem key={index}>
                        <ListItemText
                            primary={item.label}
                            primaryTypographyProps={{
                                fontSize: 13,
                                fontWeight: 'medium',
                                lineHeight: '14px',
                                mb: '2px',
                                color: theme.palette.text.secondary, // Use text.primary for title
                            }}
                        />
                    </ListItem>
                );
            }

            if (item.submenu) {
                const isActive = location.pathname.startsWith('/tharstern');
                return (
                    <React.Fragment key={index}>
                        <ListItemButton onClick={() => setTharsternOpen(!tharsternOpen)}>
                            <ListItemIcon sx={{ color: isActive ? theme.palette.primary.main : theme.palette.text.secondary }}>
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText
                                primary={item.label}
                                primaryTypographyProps={{
                                    fontSize: 14,
                                    fontWeight: 'medium',
                                    color: isActive ? theme.palette.primary.main : theme.palette.text.secondary, // Use text.primary for non-active
                                }}
                            />
                            {tharsternOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={tharsternOpen} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {item.submenu.map((subItem, subIndex) => (
                                    <ListItemButton
                                        key={subIndex}
                                        sx={{ pl: 4 }}
                                        component={RouterLink}
                                        to={subItem.link}
                                        selected={location.pathname === subItem.link}
                                        // Adjust selected styling if needed
                                    >
                                        <ListItemIcon sx={{ color: location.pathname === subItem.link ? theme.palette.primary.main : theme.palette.text.secondary }}>
                                            {subItem.icon}
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={subItem.label}
                                            primaryTypographyProps={{
                                                fontSize: 14,
                                                fontWeight: 'medium',
                                                color: location.pathname === subItem.link ? theme.palette.primary.main : theme.palette.text.secondary, // Use text.primary for non-active
                                            }}
                                        />
                                    </ListItemButton>
                                ))}
                            </List>
                        </Collapse>
                    </React.Fragment>
                );
            }

            return (
                <ListItemButton
                    key={index}
                    component={RouterLink}
                    to={item.link}
                    selected={location.pathname === item.link}
                >
                    <ListItemIcon sx={{ color: location.pathname === item.link ? theme.palette.primary.main : theme.palette.text.secondary }}>
                        {item.icon}
                    </ListItemIcon>
                    <ListItemText
                        primary={item.label}
                        primaryTypographyProps={{
                            fontSize: 14,
                            fontWeight: 'medium',
                            color: location.pathname === item.link ? theme.palette.primary.main : theme.palette.text.secondary, // Use text.primary for non-active
                        }}
                    />
                </ListItemButton>
            );
        });
    };

    // Effect to open submenu if current location is within Tharstern
    useEffect(() => {
        if (location.pathname.startsWith('/tharstern')) {
            setTharsternOpen(true);
        } else {
            setTharsternOpen(false);
        }
    }, [location.pathname]);

    // Drawer content with menu items mapped
    const drawerContent = (
        <>
            <Box sx={{ paddingTop: '15px', textAlign: 'center' }}>
                <a href="/dashboard">
                    <img src="/images/logo.png" alt="Logo" style={{ maxHeight: '40px' }} />
                </a>
            </Box>
            <Divider sx={{ paddingTop: '15px' }} />
            <List>
                {renderMenuItems(menuItems)}
            </List>
            <Divider />
            <Box
                sx={{
                    padding: '16px',
                    textAlign: 'center',
                    position: 'relative',
                    bottom: 0,
                    width: '100%',
                }}
            >
                <Button
                    component="a"
                    href="https://dpsdigital.sharepoint.com/sites/DPSHelpdesk/Lists/Tickets/AllItems.aspx"
                    target="_blank"
                    rel="noopener noreferrer"
                    variant="contained"
                    color="primary"
                    sx={{
                        textDecoration: 'none',
                        color: '#fff',
                        padding: '8px 20px',
                        borderRadius: '20px',
                        '&:hover': {
                            backgroundColor: theme.palette.primary.dark, // Use theme's primary.dark
                            color: '#fff'
                        },
                    }}
                >
                    Support
                </Button>
            </Box>
        </>
    );

    return (
        <>
            <Drawer
                sx={{
                    display: { xs: 'block', sm: 'none' },
                    zIndex: 1300,
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
                variant="temporary"
                anchor="left"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true,
                }}
            >
                {drawerContent}
            </Drawer>

            <Drawer
                sx={{
                    display: { xs: 'none', sm: 'block' },
                    width: drawerWidth,
                    flexShrink: 0,
                    zIndex: 1300,
                    '& .MuiDrawer-paper': { width: drawerWidth, boxSizing: 'border-box' },
                }}
                variant="permanent"
                anchor="left"
            >
                {drawerContent}
            </Drawer>
        </>
    );
};

export default Sidebar;
