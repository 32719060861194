// src/components/LinxDispatch.js
import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
  Autocomplete,
  ZoomInIcon,
  Typography
} from '@mui/material';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PrintIcon from '@mui/icons-material/Print';
import axios from 'axios';
import Layout from './Layout';
import SearchForm from './SearchForm';
import OrderDetails from './OrderDetails';
import OrderItems from './OrderItems';
import ConfirmationDialog from './ConfirmationDialog';

const LinxDispatch = () => {
  // activeTab: "dispatch" for Mintsoft, "kornitx" for KornitX, "reprint" for reprint.
  const [activeTab, setActiveTab] = useState('dispatch');
  const [searchParams, setSearchParams] = useState({
    orderNumber: '',
    searchType: 'orderId',
    selectedClientId: '',
  });
  const [order, setOrder] = useState(null); // holds either Mintsoft or KornitX order data
  const [orderProducts, setOrderProducts] = useState({}); // only for Mintsoft orders
  const [lineImagesMap, setLineImagesMap] = useState({}); // only for Mintsoft personalised images
  const [loading, setLoading] = useState(false);
  const [printers, setPrinters] = useState([]);
  const [selectedPrinter, setSelectedPrinter] = useState('');
  const [notification, setNotification] = useState({ open: false, message: '', severity: 'info' });
  const [confirmDialog, setConfirmDialog] = useState({ open: false, orderToReprint: null });
  const [courierServices, setCourierServices] = useState([]);
  const [selectedCourierService, setSelectedCourierService] = useState('');
  const [numberOfParcels, setNumberOfParcels] = useState('');
  const [isEditingShipment, setIsEditingShipment] = useState(false);
  const orderInputRef = useRef(null);
  const [kornitxOrderStatus, setKornitxOrderStatus] = useState('');
  const [checkingStatus, setCheckingStatus] = useState(false);
  const [confirmedLineIds, setConfirmedLineIds] = useState([]);
  const addAllButtonRef = useRef(null);
  const dispatchButtonRef = useRef(null);
  const [giftWidth, setGiftWidth] = useState('102mm');
  const [giftHeight, setGiftHeight] = useState('152mm');
  const presetSizes = {
    '4x6" (102 x 152 mm)': { width: '102mm', height: '152mm' },
    'A6 (105 x 148 mm)': { width: '105mm', height: '148mm' },
    'Custom': null,
  };
  const [selectedSize, setSelectedSize] = useState('Custom');


  useEffect(() => {
    const savedWidth = localStorage.getItem('giftWidth');
    const savedHeight = localStorage.getItem('giftHeight');
    if (savedWidth && savedHeight) {
      setGiftWidth(savedWidth);
      setGiftHeight(savedHeight);
      setSelectedSize('Custom');
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('giftWidth', giftWidth);
    localStorage.setItem('giftHeight', giftHeight);
  }, [giftWidth, giftHeight]);


  // For Mintsoft orders, we use product details and personalised images.
  // For KornitX orders, the data structure is different.
  // Pass a prop "orderSource" with value "mintsoft" or "kornitx" to reused components.
  const isPersonalised = (itemId) => Boolean(lineImagesMap[itemId]);

  // ------------------------------
  // Fetch printers on mount
  // ------------------------------
  useEffect(() => {
    axios
      .get('http://localhost:5237/printers')
      .then((response) => {
        const printerList = response.data;
        setPrinters(printerList);
        const zDesignerPrinter = printerList.find((printer) =>
          printer.startsWith('ZDesigner')
        );
        if (zDesignerPrinter) {
          setSelectedPrinter(zDesignerPrinter);
        } else if (printerList.length > 0) {
          setSelectedPrinter(printerList[0]);
        }
      })
      .catch((error) => {
        console.error('Error fetching printers:', error);
      });
  }, []);

  // ------------------------------
  // Fetch courier services on mount
  // ------------------------------
  useEffect(() => {
    axios
      .get('api/linx-dispatch/courier-services')
      .then((response) => {
        setCourierServices(response.data);
        if (response.data.length > 0) {
          setSelectedCourierService(response.data[0].ID);
        }
      })
      .catch((error) => {
        console.error('Error fetching courier services:', error);
      });
  }, []);

  // ------------------------------
  // Focus search input on tab change
  // ------------------------------
  useEffect(() => {
    if (orderInputRef.current) {
      orderInputRef.current.focus();
    }
  }, [activeTab]);

  // ------------------------------
  // For Mintsoft orders: set default numberOfParcels when order loads
  // ------------------------------
  useEffect(() => {
    if (order && activeTab === 'dispatch' && order.OrderItems?.length > 0) {
      const totalQty = order.OrderItems.reduce((sum, item) => sum + (item.Quantity || 0), 0);
      if (!numberOfParcels || Number(numberOfParcels) < totalQty) {
        setNumberOfParcels(totalQty);
      }
    }
  }, [order, activeTab, numberOfParcels]);

  // ------------------------------
  // For Mintsoft orders: fetch product details
  // ------------------------------
  useEffect(() => {
    if (order && activeTab === 'dispatch' && order.OrderItems) {
      const fetchMintsoftProducts = async () => {
        const productMap = {};
        await Promise.all(
          order.OrderItems.map(async (item) => {
            try {
              const response = await axios.get(`/api/linx-dispatch/product/${item.ProductId}`);
              productMap[item.ProductId] = response.data.product;
            } catch (err) {
              console.error(`Error fetching product ${item.ProductId}:`, err.message);
            }
          })
        );
        setOrderProducts(productMap);
      };
      fetchMintsoftProducts();
    }
  }, [order, activeTab]);

  // ------------------------------
  // For Mintsoft orders: fetch personalised images
  // ------------------------------
  const fetchPersonalisedLineImages = async () => {
    if (!order?.ID) return;
    try {
      const personalisedItems = [];
      for (const item of order.OrderItems) {
        const prodResponse = await axios.get(`/api/linx-dispatch/product/${item.ProductId}`);
        const prod = prodResponse.data.product;
        const isPers = prod.ProductInCategories.some(
          (cat) => cat.ProductCategory.Name === 'Personalised'
        );
        if (isPers) {
          personalisedItems.push(item);
        }
      }
      if (personalisedItems.length === 0) {
        console.log('No personalised items. Skipping KornitX fetch.');
        setLineImagesMap({});
        return;
      }
      const personalisedRes = await axios.get(`/api/linx-dispatch/personalised-images/${order.ID}`);
      if (personalisedRes.data && Object.keys(personalisedRes.data.personalisedImages).length > 0) {
        setLineImagesMap(personalisedRes.data.personalisedImages);
      } else {
        console.log('KornitX returned no images.');
        setLineImagesMap({});
      }
    } catch (error) {
      console.error('Error fetching personalised images:', error);
      setLineImagesMap({});
    }
  };

  useEffect(() => {
    if (order && activeTab === 'dispatch') {
      fetchPersonalisedLineImages();
    }
  }, [order, activeTab]);

  // ------------------------------
  // For Mintsoft orders: fetch KornitX status
  // ------------------------------
  const fetchKornitxStatus = async () => {
    setCheckingStatus(true);
    try {
      const response = await axios.get(`/api/linx-dispatch/kornitx-status/${order.ID}`);
      setKornitxOrderStatus(response.data.status_name || 'Unknown');
    } catch (error) {
      console.error('Error fetching KornitX status:', error);
      setKornitxOrderStatus('Unknown');
    } finally {
      setCheckingStatus(false);
    }
  };

  useEffect(() => {
    if (order && activeTab === 'dispatch') {
      fetchKornitxStatus();
    }
  }, [order, activeTab]);


  useEffect(() => {
    if (activeTab === 'kornitx') {
      setSearchParams((prev) => ({
        ...prev,
        searchType: 'orderNumber',
        selectedClientId: '',
      }));
    } else if (activeTab === 'dispatch' || activeTab === 'reprint') {
      setSearchParams((prev) => ({
        ...prev,
        searchType: 'orderId',
        selectedClientId: prev.selectedClientId || '', // Keep existing or reset
      }));
    }
  }, [activeTab]);


  // ------------------------------
  // Effect to auto-focus Dispatch Button when all items are confirmed
  // (For Mintsoft orders)
  // ------------------------------
  useEffect(() => {
    if (order && activeTab === 'dispatch' && order.OrderItems) {
      const nonPersonalisedIds = order.OrderItems
        .filter((item) => !isPersonalised(item.ID))
        .map((item) => item.ID);
      const allConfirmed = nonPersonalisedIds.every((id) => confirmedLineIds.includes(id));
      if (allConfirmed && dispatchButtonRef.current) {
        dispatchButtonRef.current.focus();
        dispatchButtonRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [confirmedLineIds, order, activeTab]);

  // ------------------------------
  // Effect to auto-focus Dispatch Button when all KornitX items are confirmed
  // ------------------------------
  useEffect(() => {
    if (order && activeTab === 'kornitx' && order.items && order.items.items) {
      const allItemIds = order.items.items.map((item) => item.id);
      const allConfirmed = allItemIds.every((id) => confirmedLineIds.includes(id));
      if (allConfirmed && dispatchButtonRef.current) {
        dispatchButtonRef.current.focus();
        dispatchButtonRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [confirmedLineIds, order, activeTab]);

  // ------------------------------
  // Notification functions
  // ------------------------------
  const showNotification = (message, severity = 'info') => {
    setNotification({ open: true, message, severity });
  };

  const handleNotificationClose = () => {
    setNotification((prev) => ({ ...prev, open: false }));
  };

  // ------------------------------
  // Reset form & clear order data
  // ------------------------------
  const resetForm = () => {
    setOrder(null);
    setOrderProducts({});
    setSearchParams((prev) => ({ ...prev, orderNumber: '' }));
    setIsEditingShipment(false);
    if (orderInputRef.current) {
      orderInputRef.current.focus();
    }
  };

  // ------------------------------
  // For Mintsoft orders: Toggle Edit
  // ------------------------------
  const handleToggleEdit = () => {
    if (!isEditingShipment && order) {
      setNumberOfParcels(order.NumberOfParcels || numberOfParcels);
      setSelectedCourierService(order.CourierServiceId || selectedCourierService);
    }
    setIsEditingShipment(!isEditingShipment);
  };

  // ------------------------------
  // Handle printing for Mintsoft orders
  // ------------------------------
  const handlePrintDocuments = async (orderForPrint = order) => {
    if (!orderForPrint) {
      showNotification('Order not found for printing', 'error');
      return;
    }
    try {
      const response = await axios.get(`/api/linx-dispatch/documents/${orderForPrint.ID}`);
      const { shippingLabel, additionalDocs } = response.data;

      const getBlobUrl = (base64Data, contentType) => {
        const byteCharacters = atob(base64Data);
        const byteNumbers = [...byteCharacters].map((char) => char.charCodeAt(0));
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: contentType });
        return window.URL.createObjectURL(blob);
      };

      if (additionalDocs?.length > 0) {
        additionalDocs.forEach((doc) => {
          if (doc?.Base64Data) {
            const docUrl = getBlobUrl(doc.Base64Data, 'application/pdf');
            const printWindow = window.open(docUrl, '_blank');
            printWindow.addEventListener('load', () => {
              printWindow.print();
            });
          }
        });
      }

      if (shippingLabel?.ZPLData && window.BrowserPrint) {
        window.BrowserPrint.getLocalDevices(
          'printer',
          (devices) => {
            const zebraPrinter = devices.find((d) => d.name === 'ZDesigner GK420d');
            if (zebraPrinter) {
              zebraPrinter.send(
                shippingLabel.ZPLData,
                () => {
                  showNotification('Label printed successfully via Zebra printer', 'success');
                },
                (error) => {
                  console.error('Zebra printer error:', error);
                  fallbackLabelPrint(shippingLabel);
                }
              );
            } else {
              fallbackLabelPrint(shippingLabel);
            }
          },
          (error) => {
            console.error('BrowserPrint error:', error);
            fallbackLabelPrint(shippingLabel);
          }
        );
      } else if (shippingLabel?.Base64Data && printers.length > 0 && selectedPrinter) {
        try {
          await axios.post('http://localhost:5237/print', {
            PrinterName: selectedPrinter,
            Content: shippingLabel.Base64Data,
            ContentType: 'application/pdf',
          });
          showNotification('Label printed successfully via local proxy', 'success');
        } catch (proxyError) {
          console.error('Local print proxy error:', proxyError);
          fallbackLabelPrint(shippingLabel);
        }
      } else {
        fallbackLabelPrint(shippingLabel);
      }

      function fallbackLabelPrint(label) {
        if (label?.Base64Data) {
          const labelUrl = getBlobUrl(label.Base64Data, 'application/pdf');
          const printWindow = window.open(labelUrl, '_blank');
          printWindow.addEventListener('load', () => {
            printWindow.print();
          });
          showNotification('Printing via browser fallback. Please save or print manually.', 'info');
        } else {
          showNotification('No shipping label available to print', 'error');
        }
      }
    } catch (error) {
      console.error('Error printing documents:', error);
      showNotification('Error fetching print documents', 'error');
    }
  };

  // ------------------------------
  // Handle search for Mintsoft orders
  // ------------------------------
  const handleMintsoftSearch = async () => {
    if (!searchParams.orderNumber.trim()) {
      showNotification('Please enter a valid value', 'warning');
      return;
    }
    if (searchParams.searchType === 'orderNumber' && !searchParams.selectedClientId) {
      showNotification('Please select a client', 'warning');
      return;
    }
    setLoading(true);
    try {
      let response;
      if (searchParams.searchType === 'orderNumber') {
        response = await axios.get('/api/linx-dispatch/search', {
          params: {
            orderNumber: searchParams.orderNumber,
            clientId: searchParams.selectedClientId,
          },
        });
      } else {
        response = await axios.get(`/api/linx-dispatch/order/${searchParams.orderNumber}?reprint=true`);
      }
      if (response.data.success) {
        const fetchedOrder = response.data.order;
        if (fetchedOrder.OrderStatusId === 4) {  // Dispatched status
          setConfirmDialog({
            open: true,
            orderToReprint: fetchedOrder.ID,
          });
          showNotification(`Order ${fetchedOrder.OrderNumber} is already dispatched. Do you want to reprint?`, 'info');
        } else {
          setOrder(fetchedOrder);
        }
      } else {
        const errMsg = response.data.message;
        if (errMsg && errMsg.includes('DESPATCHED already')) {
          setConfirmDialog({
            open: true,
            orderToReprint: response.data.orderId || searchParams.orderNumber,
          });
        } else {
          showNotification(errMsg, 'error');
        }
      }
    } catch (error) {
      const errMsg = error.response?.data?.message || error.message;
      showNotification(errMsg, 'error');
    }
    setLoading(false);
  };


  // ------------------------------
  // Handle search for KornitX orders
  // ------------------------------
  const handleKornitxSearch = async () => {
    if (!searchParams.orderNumber.trim()) {
      showNotification('Please enter a valid order number', 'warning');
      return;
    }
    setLoading(true);
    try {
      const response = await axios.get(
        `/api/linx-dispatch/kornitx-order/${encodeURIComponent(searchParams.orderNumber)}`
      );
      if (response.data.success) {
        const fetchedOrder = response.data.order;
        const status = fetchedOrder.status_name;
        if (['Received', 'In Production'].includes(status)) {
          setOrder(fetchedOrder);
        } else {
          showNotification(
            `KornitX order cannot be dispatched. Current status: ${status}`,
            'warning'
          );
        }
      } else {
        showNotification(response.data.message, 'error');
      }
    } catch (error) {
      const errMsg = error.response?.data?.message || error.message;
      showNotification(errMsg, 'error');
    }
    setLoading(false);
  };




  // ------------------------------
  // Handle dispatch for Mintsoft orders
  // ------------------------------
  const allItemsConfirmed = (() => {
    if (!order) return false;

    if (activeTab === 'dispatch' && order.OrderItems) {
      const allItemIds = order.OrderItems.map((item) => item.ID);
      return allItemIds.every((id) => confirmedLineIds.includes(id));
    }

    if (activeTab === 'kornitx' && order.items?.items) {
      const allItemIds = order.items.items.map((item) => item.id);
      return allItemIds.every((id) => confirmedLineIds.includes(id));
    }

    return false;
  })();

  const handleDispatchOrder = async () => {
    if (!order) return;
    setLoading(true);
    try {
      const hasPersonalisedItems = order.OrderItems.some((item) => isPersonalised(item.ID));
      if (hasPersonalisedItems) {
        const statusRes = await axios.get(`/api/linx-dispatch/kornitx-status/${order.ID}`);
        const refreshedStatus = statusRes.data.status_name || 'Unknown';
        setKornitxOrderStatus(refreshedStatus);
        if (refreshedStatus !== 'In Production') {
          showNotification(`KornitX order is not In Production (Current: ${refreshedStatus})`, 'warning');
          setLoading(false);
          return;
        }
      }
      const totalQty = order.OrderItems.reduce((sum, item) => sum + (item.Quantity || 0), 0);
      const courierIdToUse = isEditingShipment
        ? selectedCourierService
        : order.CourierServiceId || selectedCourierService;
      const parcelsToUse = isEditingShipment
        ? numberOfParcels
        : order.NumberOfParcels || numberOfParcels;
      if (Number(parcelsToUse) > totalQty) {
        showNotification(`Number of Parcels cannot be greater than total items (${totalQty})`, 'warning');
        setLoading(false);
        return;
      }
      const response = await axios.post(
        `/api/linx-dispatch/dispatch/${order.ID}?orderNumber=${order.OrderNumber}` +
        `&CourierServiceId=${courierIdToUse}&NumberOfParcels=${parcelsToUse}`
      );
      if (response.data.success) {
        showNotification(response.data.message, 'success');
        await handlePrintDocuments();
        resetForm();
      } else {
        showNotification(response.data.message, 'error');
      }
    } catch (error) {
      console.error('Dispatch error:', error);
      showNotification(error.response?.data?.message || error.message, 'error');
    }
    setLoading(false);
  };

  // ------------------------------
  // Handle dispatch for KornitX orders only
  // ------------------------------
  // New helper: Clean a base64 string by removing any data URL prefix and whitespace/newlines
  function cleanBase64(b64Data) {
    // Remove data URL prefix if present
    if (b64Data.startsWith('data:')) {
      b64Data = b64Data.split(',')[1];
    }
    // Remove all non-base64 characters including line breaks, zero-width spaces, etc.
    return b64Data.replace(/[^A-Za-z0-9+/=]/g, '');
  }

  // Updated helper: Convert Base64 to Blob using the cleaned string
  function b64toBlob(b64Data, contentType) {
    const cleanedB64 = cleanBase64(b64Data);
    //console.log('PDF base64 snippet:', b64Data.slice(0, 30));
    const byteCharacters = atob(cleanedB64);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  // Updated helper: Handle Gift Message PDF printing for KornitX orders
  const handleGiftMessagePrint = async (giftMessage, selectedPrinter) => {
    try {
      const res = await axios.post('/api/linx-dispatch/gift-message-to-pdf', {
        giftMessage,
        width: giftWidth,
        height: giftHeight,
      });

      if (res.data && res.data.pdfBase64) {
        const blob = b64toBlob(res.data.pdfBase64, 'application/pdf');
        //console.log('PDF Blob size:', blob.size);

        //Fallback to prompt print windows for manual printing
        /*if (!selectedPrinter || !printers.includes(selectedPrinter)) {
          // No valid printer, fallback to open in browser
          const pdfUrl = URL.createObjectURL(blob);
          const printWindow = window.open(pdfUrl, '_blank');
          printWindow.addEventListener('load', () => {
            printWindow.print();
          });
          showNotification('No printer selected. Opened PDF in new tab for manual print.', 'info');
          return;
        }*/

        //Sending PDF FunkyPigeon Gift Message to Printer
        //console.log('Sending to printer:', selectedPrinter);
        /*await axios.post('http://localhost:5237/print', {
          PrinterName: selectedPrinter,
          Content: res.data.pdfBase64,
          ContentType: 'application/pdf',
        });
  
        */

        const pdfUrl = URL.createObjectURL(blob);
        const printWindow = window.open(pdfUrl, '_blank');
        printWindow.addEventListener('load', () => {
          printWindow.print();
        });
        //showNotification('No printer selected. Opened PDF in new tab for manual print.', 'info');
        showNotification('Gift message printed successfully.', 'success');
      } else {
        showNotification('Gift message PDF generation failed.', 'error');
      }
    } catch (error) {
      console.error('Error printing gift message:', error);
      showNotification('Error printing gift message.', 'error');
    }
  };


  // Modify the KornitX dispatch function to include GiftMessage handling
  const handleKornitxDispatch = async () => {
    if (!order) return;
    setLoading(true);
    try {
      // For KornitX, dispatch using external_ref.
      const externalRef = order.external_ref;
      //console.log(order.GiftMessage);

      /* const dispatchRes = await axios.post(
         `/api/linx-dispatch/kornitx-dispatch/${encodeURIComponent(externalRef)}`
       );*/

      // Simulate a successful dispatch response (or use your real dispatch endpoint)
      const dispatchRes = { data: { success: true } };
      if (dispatchRes.data.success) {
        showNotification('KornitX order dispatched and shipment label created.', 'success');

        // Check if a GiftMessage attribute exists (it is added in getKornitXOrder)
        if (order.GiftMessage) {
          // Trigger gift message PDF conversion and automatic printing
          await handleGiftMessagePrint(order.GiftMessage, selectedPrinter);
        }
        resetForm();
      } else {
        showNotification(dispatchRes.data.message, 'error');
      }
    } catch (error) {
      console.error('Error dispatching KornitX order:', error);
      showNotification(error.response?.data?.message || error.message, 'error');
    }
    setLoading(false);
  };

  // ------------------------------
  // Handle reprint for Mintsoft orders
  // ------------------------------
  const handleReprint = async () => {
    if (!searchParams.orderNumber.trim()) {
      showNotification('Please enter a valid value', 'warning');
      return;
    }
    setLoading(true);
    try {
      const response = await axios.get(
        `/api/linx-dispatch/order/${searchParams.orderNumber}?reprint=true`
      );
      if (response.data.success) {
        await handlePrintDocuments(response.data.order);
        showNotification('Reprint initiated. Check your print dialog.', 'success');
        resetForm();
      } else {
        showNotification(response.data.message, 'error');
      }
    } catch (error) {
      const errMsg = error.response?.data?.message || error.message;
      showNotification(errMsg, 'error');
    }
    setLoading(false);
  };

  const handleDialogConfirm = async () => {
    try {
      const reprintResponse = await axios.get(
        `/api/linx-dispatch/order/${confirmDialog.orderToReprint}?reprint=true`
      );
      if (reprintResponse.data.success) {
        await handlePrintDocuments(reprintResponse.data.order);
      }
    } catch (error) {
      showNotification(error.response?.data?.message || error.message, 'error');
    }
    setConfirmDialog({ open: false, orderToReprint: null });
    resetForm();
  };

  const handleDialogClose = () => {
    setConfirmDialog({ open: false, orderToReprint: null });
    resetForm();
  };

  // ------------------------------
  // Re-fetch Mintsoft product details if order changes (optional)
  // ------------------------------
  useEffect(() => {
    if (order && activeTab === 'dispatch' && order.OrderItems) {
      const fetchProducts = async () => {
        const productMap = {};
        await Promise.all(
          order.OrderItems.map(async (item) => {
            try {
              const response = await axios.get(`/api/linx-dispatch/product/${item.ProductId}`);
              productMap[item.ProductId] = response.data.product;
            } catch (err) {
              console.error(`Error fetching product ${item.ProductId}:`, err.message);
            }
          })
        );
        setOrderProducts(productMap);
      };
      fetchProducts();
    }
  }, [order, activeTab]);

  // ------------------------------
  // Focus "Add All Items" button when order loads
  // ------------------------------
  useEffect(() => {
    if (order && addAllButtonRef.current) {
      addAllButtonRef.current.focus();
    }
  }, [order]);

  // ------------------------------
  // For Mintsoft orders: if all non-personalised items are confirmed, focus dispatch button
  // ------------------------------
  useEffect(() => {
    if (order && activeTab === 'dispatch' && order.OrderItems) {
      const nonPersonalisedIds = order.OrderItems
        .filter((item) => !isPersonalised(item.ID))
        .map((item) => item.ID);
      const allConfirmed = nonPersonalisedIds.every((id) => confirmedLineIds.includes(id));
      if (allConfirmed && dispatchButtonRef.current) {
        dispatchButtonRef.current.focus();
        dispatchButtonRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [confirmedLineIds, order, activeTab]);

  // ------------------------------
  // For KornitX orders: if all items (from order.items.items) are confirmed, focus dispatch button
  // ------------------------------
  useEffect(() => {
    if (order && activeTab === 'kornitx' && order.items && order.items.items) {
      const allItemIds = order.items.items.map((item) => item.id);
      const allConfirmed = allItemIds.every((id) => confirmedLineIds.includes(id));
      if (allConfirmed && dispatchButtonRef.current) {
        dispatchButtonRef.current.focus();
        dispatchButtonRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [confirmedLineIds, order, activeTab]);

  // ------------------------------
  // Global listen to F2 keyboard shortcut for quickly adding all items and dispatching
  // ------------------------------
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'F2') {
        e.preventDefault();

        if (!order) return;

        if (activeTab === 'dispatch' && order.OrderItems?.length > 0) {
          const allItemIds = order.OrderItems.map((item) => item.ID);
          setConfirmedLineIds(allItemIds);

          showNotification('All items confirmed and dispatch initiated with F2 shortcut!', 'info');

          setTimeout(() => {
            handleDispatchOrder();
          }, 100);
        }

        if (activeTab === 'kornitx' && order.items?.items?.length > 0) {
          const allItemIds = order.items.items.map((item) => item.id);
          setConfirmedLineIds(allItemIds);

          showNotification('All items confirmed and dispatch initiated with F2 shortcut!', 'info');

          setTimeout(() => {
            handleKornitxDispatch();
          }, 100);
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [order, activeTab]);

  useEffect(() => {
    if (order && !sessionStorage.getItem('f2TipShown')) {
      showNotification('💡 Tip: Press F2 to confirm all items and dispatch instantly.', 'info');
      sessionStorage.setItem('f2TipShown', 'true');
    }
  }, [order]);


  // ------------------------------
  // Render JSX
  // ------------------------------
  return (
    <Layout>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        {/* Mintsoft Toggle Group */}
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mb: 2 }}>
          <ToggleButtonGroup
            value={activeTab}
            exclusive
            onChange={(e, newVal) => {
              if (newVal) {
                setActiveTab(newVal);
                resetForm();
              }
            }}
            color="primary"
            size="small"
          >
            <ToggleButton value="dispatch">
              <LocalShippingIcon sx={{ mr: 1 }} /> Mintsoft Dispatch
            </ToggleButton>

            <ToggleButton value="reprint">
              <PrintIcon sx={{ mr: 1 }} /> Reprint
            </ToggleButton>
          </ToggleButtonGroup>

          {/* KornitX Toggle Group */}
          <ToggleButtonGroup
            value={activeTab}
            exclusive
            onChange={(e, newVal) => {
              if (newVal) {
                setActiveTab(newVal);
                resetForm();
              }
            }}
            color="secondary"
            size="small"
          >
            <ToggleButton value="kornitx">
              <LocalShippingIcon sx={{ mr: 1 }} /> KornitX Dispatch
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>

        {/* Render Search Form based on activeTab */}
        {activeTab === 'dispatch' && !order && (
          <SearchForm
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            printers={printers}
            selectedPrinter={selectedPrinter}
            setSelectedPrinter={setSelectedPrinter}
            onSearch={handleMintsoftSearch}
            loading={loading}
            inputRef={orderInputRef}
            isDispatch
            orderSource="mintsoft"
          />
        )}
        {activeTab === 'kornitx' && !order && (
          <SearchForm
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            printers={printers}
            selectedPrinter={selectedPrinter}
            setSelectedPrinter={setSelectedPrinter}
            onSearch={handleKornitxSearch}
            loading={loading}
            inputRef={orderInputRef}
            isDispatch
            orderSource="kornitx"
          />
        )}
        {activeTab === 'reprint' && !order && (
          <SearchForm
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            printers={printers}
            selectedPrinter={selectedPrinter}
            setSelectedPrinter={setSelectedPrinter}
            onSearch={handleReprint}
            loading={loading}
            inputRef={orderInputRef}
            isDispatch={false}
            orderSource="mintsoft"
          />
        )}

        {/* Mintsoft Dispatch Order */}
        {activeTab === 'dispatch' && order && (
          <Paper
            sx={{
              p: 3,
              boxShadow: 4,
              borderRadius: 2,
              display: 'flex',
              flexDirection: 'column',
              minHeight: '400px',
            }}
          >
            <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between' }}>
              <Button variant="outlined" onClick={resetForm}>
                Back
              </Button>
              <Button
                ref={dispatchButtonRef}
                variant="contained"
                color="secondary"
                onClick={handleDispatchOrder}
                size="large"
                disabled={!allItemsConfirmed || loading}
              >
                {loading ? <CircularProgress size={24} /> : 'Dispatch Order'}
              </Button>

            </Box>

            <OrderDetails
              order={order}
              courierServices={courierServices}
              selectedCourierService={selectedCourierService}
              setSelectedCourierService={setSelectedCourierService}
              numberOfParcels={numberOfParcels}
              setNumberOfParcels={setNumberOfParcels}
              isEditingShipment={isEditingShipment}
              onToggleEdit={handleToggleEdit}
              checkingStatus={checkingStatus}
              kornitxOrderStatus={kornitxOrderStatus}
              fetchKornitxStatus={fetchKornitxStatus}
              orderSource="mintsoft"
            />

            <Box
              sx={{
                overflowY: 'auto',
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                position: 'relative',
              }}
              tabIndex={0}
            >
              <Box
                sx={{
                  position: 'sticky',
                  top: 0,
                  zIndex: 2,
                  backgroundColor: 'background.paper',
                  p: 1,
                  borderBottom: '1px solid',
                  borderColor: 'divider',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Button
                    ref={addAllButtonRef}
                    variant="contained"
                    color="secondary"
                    title="Shortcut: F2 to confirm all items and dispatch"
                    onClick={() => {
                      const allItemIds = order.OrderItems.map((item) => item.ID);
                      setConfirmedLineIds((prev) =>
                        Array.from(new Set([...prev, ...allItemIds]))
                      );
                    }}
                  >
                    Add All Items (↵ Enter)
                  </Button>

                  <Typography variant="body2" color="text.secondary" sx={{ fontStyle: 'italic' }}>
                    Tip: Press <strong>F2</strong> to confirm and dispatch instantly
                  </Typography>
                </Box>

              </Box>
              <OrderItems
                order={order}
                orderProducts={orderProducts}
                lineImagesMap={lineImagesMap}
                confirmedLineIds={confirmedLineIds}
                setConfirmedLineIds={setConfirmedLineIds}
                isPersonalised={isPersonalised}
                orderSource="mintsoft"
              />
            </Box>
          </Paper>
        )}

        {/* KornitX Dispatch Order */}
        {activeTab === 'kornitx' && order && (
          <Paper
            sx={{
              p: 3,
              boxShadow: 4,
              borderRadius: 2,
              display: 'flex',
              flexDirection: 'column',
              minHeight: '400px',
            }}
          >
            <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap', gap: 2 }}>
              <Button variant="outlined" onClick={resetForm}>
                Back
              </Button>

              {/* Gift Message Paper Size Config */}
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Box sx={{ fontWeight: 'bold' }}>Gift Msg Size:</Box>
                <select
                  value={selectedSize}
                  onChange={(e) => {
                    const val = e.target.value;
                    setSelectedSize(val);
                    if (presetSizes[val]) {
                      setGiftWidth(presetSizes[val].width);
                      setGiftHeight(presetSizes[val].height);
                    }
                  }}
                  style={{ height: 32 }}
                >
                  {Object.keys(presetSizes).map((label) => (
                    <option key={label} value={label}>{label}</option>
                  ))}
                </select>
                <input
                  type="text"
                  value={giftWidth}
                  onChange={(e) => {
                    const value = e.target.value.trim();
                    setGiftWidth(value.endsWith('mm') ? value : `${parseFloat(value)}mm`);
                    setSelectedSize('Custom');
                  }}
                  placeholder="Width"
                  style={{ width: 70 }}
                />
                <input
                  type="text"
                  value={giftHeight}
                  onChange={(e) => {
                    const value = e.target.value.trim();
                    setGiftHeight(value.endsWith('mm') ? value : `${parseFloat(value)}mm`);
                    setSelectedSize('Custom');
                  }}
                  placeholder="Height"
                  style={{ width: 70 }}
                />
              </Box>


              <Button
                ref={dispatchButtonRef}
                variant="contained"
                color="secondary"
                onClick={handleKornitxDispatch}
                size="large"
                disabled={!allItemsConfirmed || loading}
              >
                {loading ? <CircularProgress size={24} /> : 'Dispatch KornitX Order'}
              </Button>


            </Box>

            <OrderDetails
              order={order}
              courierServices={[]} // not used for KornitX orders
              selectedCourierService={''}
              setSelectedCourierService={() => { }}
              numberOfParcels={''}
              setNumberOfParcels={() => { }}
              isEditingShipment={false}
              onToggleEdit={() => { }}
              checkingStatus={checkingStatus}
              kornitxOrderStatus={kornitxOrderStatus}
              fetchKornitxStatus={fetchKornitxStatus}
              orderSource="kornitx"
            />


            <Box
              sx={{
                overflowY: 'auto',
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                position: 'relative',
              }}
              tabIndex={0}
            >
              <Box
                sx={{
                  position: 'sticky',
                  top: 0,
                  zIndex: 2,
                  backgroundColor: 'background.paper',
                  p: 1,
                  borderBottom: '1px solid',
                  borderColor: 'divider',
                }}
              >
<Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
  <Button
    ref={addAllButtonRef}
    variant="contained"
    color="secondary"
    title="Shortcut: F2 to confirm all items and dispatch"
    onClick={() => {
      const allItemIds = order.items?.items?.map((item) => item.id) || [];
      setConfirmedLineIds(allItemIds);
    }}
  >
    Add All Items (↵ Enter)
  </Button>

  <Typography variant="body2" color="text.secondary" sx={{ fontStyle: 'italic' }}>
    Tip: Press <strong>F2</strong> to confirm and dispatch instantly
  </Typography>
</Box>

              </Box>
              <OrderItems
                order={order}
                orderProducts={{}} // not used for KornitX
                lineImagesMap={{}} // artwork is in the item itself
                confirmedLineIds={confirmedLineIds}
                setConfirmedLineIds={setConfirmedLineIds}
                orderSource="kornitx"
              />
            </Box>
          </Paper>
        )}

        {/* Reprint (Mintsoft) */}
        {activeTab === 'reprint' && order && (
          <Paper
            sx={{
              p: 3,
              boxShadow: 4,
              borderRadius: 2,
              display: 'flex',
              flexDirection: 'column',
              minHeight: '400px',
            }}
          >
            <OrderDetails
              order={order}
              courierServices={courierServices}
              selectedCourierService={selectedCourierService}
              setSelectedCourierService={setSelectedCourierService}
              numberOfParcels={numberOfParcels}
              setNumberOfParcels={setNumberOfParcels}
              isEditingShipment={isEditingShipment}
              onToggleEdit={handleToggleEdit}
              checkingStatus={checkingStatus}
              kornitxOrderStatus={kornitxOrderStatus}
              fetchKornitxStatus={fetchKornitxStatus}
              orderSource="mintsoft"
            />
          </Paper>
        )}

        <Snackbar
          open={notification.open}
          autoHideDuration={6000}
          onClose={handleNotificationClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert onClose={handleNotificationClose} severity={notification.severity} sx={{ width: '100%' }}>
            {notification.message}
          </Alert>
        </Snackbar>

        <ConfirmationDialog
          open={confirmDialog.open}
          orderToReprint={confirmDialog.orderToReprint}
          onClose={handleDialogClose}
          onConfirm={handleDialogConfirm}
        />
      </Box>
    </Layout>
  );
};

export default LinxDispatch;
