// frontend/src/components/Layout.js
import React, { useState } from 'react';
import Sidebar from './Sidebar';
import Navigation from './Navigation';
import Footer from './Footer';
import { Box, CssBaseline } from '@mui/material';
import BreadcrumbComponent from './BreadcrumbComponent';
import ScrollToTop from './ScrollToTop'; // Import the new component


const Layout = ({ children }) => {
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <CssBaseline />
            <Navigation handleDrawerToggle={handleDrawerToggle} />
            <Box sx={{ display: 'flex', flexGrow: 1 }}>
                <Sidebar mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        padding: 3,
                        backgroundColor: 'background.default',
                        minHeight: '100vh',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <ScrollToTop /> {/* Insert ScrollToTop here */}
                    {/* Ensure this Box aligns content properly */}
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, mt:5 }}>
                        <BreadcrumbComponent />
                        {/* Add any other components (like title) here, and they will align properly */}
                    </Box>
                    {children}
                </Box>
            </Box>
            <Box>
                <Footer />
            </Box>
        </Box>
    );
};

export default Layout;
